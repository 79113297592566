<template>
    <div class="banner_box">
        <div class="banner_scroll" :style="ans">
            <div class="item">
                <img src="../../public/img/memefi.png" alt="">
            </div>
            <div class="item">
                <img src="../../public/img/memefi.png" alt="">
            </div>
            <div class="item">
                <img src="../../public/img/memefi.png" alt="">
            </div>
            <div class="item">
                <img src="../../public/img/memefi.png" alt="">
            </div>
            <div class="item">
                <img src="../../public/img/memefi.png" alt="">
            </div>
            <div class="item">
                <img src="../../public/img/memefi.png" alt="">
            </div>
            <div class="item">
                <img src="../../public/img/memefi.png" alt="">
            </div>
            <div class="item">
                <img src="../../public/img/memefi.png" alt="">
            </div>
            <div class="item">
                <img src="../../public/img/memefi.png" alt="">
            </div>
            <div class="item">
                <img src="../../public/img/memefi.png" alt="">
            </div>
            <div class="item">
                <img src="../../public/img/memefi.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            ans: ''
        }
    },
    created() {
        this.an_rows()
    },
    methods: {
        an_rows() {

            let index = 1
            setTimeout(() => {
                index++
                this.ans = `transition:all ${this.ss}s linear;transform:translateX(-${index * 200}px)`
            })


            setInterval(() => {
                index++
                if (index>4) {
                    index=0
                    this.ans = `transition:none;transform:translateX(-${index * 200}px)`
                    return;
                }
                this.ans = `transition:all ${this.ss}s linear;transform:translateX(-${index * 200}px)`
            }, this.speeds);

        }
    },

    props: {
        // 速度
        speeds: Number,
        ss: Number,
    },
}
</script>

<style lang="less" scoped>
.banner_box {
    width: 100%;
    overflow: hidden;
    background-color: rgba(255, 255, 255, .1);
    border: 2px solid rgba(255, 255, 255, .36);
    padding: 3px 0;

    .banner_scroll {
        width: 2400px;
        display: flex;
        justify-content: space-between;
    }

    .banner_scroll .item {
        width: 200px;
        // padding: 20px 0;
        font-size: 0;
        img {
            width: 100%;
        }
    }
}
</style>