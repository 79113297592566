<template>
    <div class="howbg">
        <div class="container" id="HOW">
            <div class="title">
                <!-- <img class="tit_svg" src="../assets/images/howtits.png" alt=""> -->
                <img class="tit_logo" src="../assets/images/how_tit.png" alt="">
            </div>
            <div class="main">
                <div class="card_list" v-for="(item, index) in card" :key="index">
                    <div class="box">
                        <div class="icon">
                            <img :src="item.pic" alt="">
                        </div>
                        <div class="text">
                            <h3>{{ item.tit }}</h3>
                            <p>{{ item.p }}</p>
                        </div>
                    </div>
                </div>
                <div class="how01">
                    <img src="../assets/images/how02.png" alt="">
                </div>
                <div class="pile">
                    <img src="../assets/images/how_01.png" alt="">
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            card: [{
                pic: '../img/howcard1.png',
                bg: '../img/cardbg1.png',
                tit: 'Create a Wallet',
                p: 'download metamask or your wallet of choice from the app store or google play store for free. Desktopusers, download the google chrome extension by going to metamask.io.'
            }, {
                pic: '../img/howcard2.png',
                bg: '../img/cardbg2.png',
                tit: 'Get Some ETH',
                p: 'have ETH in your wallet to switch to $CYBERTRUCK BEAR. If you don’t have any ETH, you can buy directly on metamask, transfer from another wallet, or buy on another exchange and send it to your wallet.'
            }, {
                pic: '../img/howcard3.png',
                bg: '../img/cardbg3.png',
                tit: 'Go to PancakeSwap',
                p: 'connect to PancakeSwap. Go to pancakeswap.finance in google chrome or on the browser inside your Metamask app. Connect your wallet. Paste the $X token address into pancakeswap, select CyberTruck, and confirm. When Metamask prompts you for a wallet signature, sign.'
            }, {
                pic: '../img/howcard4.png',
                bg: '../img/cardbg4.png',
                tit: 'Switch ETH for $CYBERTRUCK',
                p: 'switch ETH for $CyberTruck. We have ZERO taxes so you don’t need to worry about buying with  a specific slippage, although you may need to use slippage during times of market volatility.'
            },]
        }
    },
}
</script>

<style lang="less" scoped>
.howbg {
    position: relative;
    // background: url('../assets/images/howbg.png') no-repeat;
    background-size: 50% auto;
    background-position: center -100px;
}

.how01 {
    position: absolute;
    right: 0;
    top: 60%;
    width: 8%;
}

.titles {
    text-align: center;
    margin-bottom: -40px;
    width: 100%;

    img {
        width: 100%;
    }
}

.svg {
    display: flex;
    align-items: center;
    justify-content: center;

    .tit_svg {
        position: absolute;
        width: 350px;
    }
}

.how01 img {
    width: 100%;
}

.container {
    // margin-top: 80px;

    .dia {
        position: absolute;
        left: 0;
        width: 230px;

        img {
            transform: translateY(50%);
            width: 100%;
        }
    }

    .pile {
        position: absolute;
        left: 0%;
        bottom: -25%;
        width: 12%;

        img {
            width: 100%;
        }
    }

    .cap {
        position: absolute;
        left: 20%;
        width: 70px;

        img {
            transform: translateY(20%);
            width: 100%;
        }
    }
}

.title {
    text-align: center;
    // margin-bottom: -70px;
    padding: 50px 0;

    img {
        width: 22%;
    }
}

.card_list {
    position: relative;
    margin-bottom: 20px;
    width: 100%;
    padding: 15px 5px 5px;
    position: relative;
    margin-bottom: 20px;
    padding: 15px 5px 5px;
    box-sizing: border-box;
    border-radius: 12px;
    color: #fff;
    font-style: normal;
    backdrop-filter: blur(3px);
    border: 1px solid #4e4d4d;
    background:linear-gradient(to right,#0c0c0c,#323333);
    overflow: hidden;
    .cardbg {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;

        img {
            height: 100%;
        }
    }

    .box {
        display: flex;
        color: #fff;
        align-items: center;
        padding: 20px 0;
    }

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 155px;
        margin: 20px;

        img {
            width: 100%;
        }
    }

    .text {
        padding: 0 40px;
        flex: 1;

        h3 {
            display: inline-block;
            font-size: 34px;
            font-weight: normal;
            padding-bottom: 3px;
            font-family: 'StiffStaff';
            // border-bottom: 6px solid #76c454;
        }

        p {
            margin-top: 18px;
            line-height: 32px;
            font-size: 22px;
            opacity: .8;
        }
    }
}

@media screen and (max-width:1200px) {
    .title {
        margin-bottom: -10%;
    }
}


@media screen and (max-width:920px) {
    .svg {
        .tit_svg {
            width: 90%;
            margin: 0 auto 40px;
        }
    }

}

@media screen and (max-width:700px) {

    .titles {
        padding-top: 100px;
    }
}

@media screen and (max-width:530px) {
    .tit_logo {
        width: 60% !important;
    }
}

@media screen and (max-width:400px) {
    .title {
        width: 90%;
        margin: 60px auto 20px;

        img {
            width: 100%;
        }
    }

    .card_list {
        padding: 20px 0;

        .icon img {
            width: 240%;
        }
    }
}
</style>